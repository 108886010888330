import { Component } from 'preact'
import { connect } from 'unistore/preact'
import { API_URL } from '../settings'
import SearchHeader from '../components/search-header'
import ResultsGrid from '../components/results-grid'
import ShareWidget from '../components/share-widget'
import Favicon from '../components/favicon'
import Title from '../components/title'
import OgImage from '../components/ogimage'
import PaletteRow from '../components/palette-row'
import ad from '../ad.json'
import blockedQueries from '../blockedQueries.json'
import s from './search.sass'

const MAX_PALETTE = 8

const actions = {
  removePaletteColor: (state, index) => {
    const currentPalette = state.currentPalette.slice()
    currentPalette.splice(index, 1)
    return { currentPalette }
  },
  onPin: (state, data) => {
    const currentPalette = state.currentPalette.slice()
    let index = -1

    for (let i = 0; i < currentPalette.length; i++) {
      if (currentPalette[i].color === data.color) {
        index = i
        break
      }
    }

    // Toggle if already exists
    if (index > -1) {
      currentPalette.splice(index, 1)
      return { currentPalette }
    }

    return {
      currentPalette: currentPalette
        .slice(0, MAX_PALETTE - 1)
        .concat(data)
    }
  }
}

export default connect('currentPalette', actions)(
  class extends Component {
    // eslint-disable-next-line react/no-deprecated
    componentWillMount () {
      this.getAdChild()
    }

    componentDidMount () {
      this.search(this.props.query)
    }

    componentDidUpdate (props) {
      if (props.query !== this.props.query) {
        this.getAdChild()
        this.search(this.props.query)
      }
    }

    getAdChild () {
      if (ad && ad.search) {
        const randomItem = Math.floor(Math.random() * 7 + 5)
        this.setState({ adChild: randomItem })
      }
    }

    search (query) {
      const q = query.toLowerCase()
      if (blockedQueries.find(blocked => q.includes(blocked))) {
        this.setState({ items: [], isBlocked: true })
        return
      }

      fetch(API_URL + '/search?query=' + encodeURIComponent(query))
        .then(r => r.json())
        .then(({ primary, secondary, colors }) => {
          // Cancel old search
          if (query !== this.props.query) return

          // Add AD if exists.
          if (this.state.adChild != null) {
            const adIndex = Math.floor(Math.random() * ad.search.length)
            colors.splice(this.state.adChild, 0, { ad: ad.search[adIndex] })
          }

          this.setState({
            isBlocked: false,
            items: colors,
            primaryColor: primary,
            secondaryColor: secondary
          })

          if (process.env.NODE_ENV === 'development') {
            console.log(JSON.stringify({
              text: query,
              primary,
              secondary,
              colors: Array.from({ length: 29 }, (_, i) => {
                const j = i % colors.length
                return { color: colors[j].color, light: colors[j].light }
              })
            }))
          }
        })
        .catch(_ => {
          if (query !== this.props.query) return

          this.setState({ items: [], isBlocked: false })
        })
    }

    render ({ query, currentPalette, onPin }, { items, adChild, primaryColor, secondaryColor, isBlocked }) {
      return (
        <div class={s.view}>
          <Title title={query} />
          <OgImage image={null} />
          <Favicon
            primary={primaryColor}
            secondary={secondaryColor}
          />
          <SearchHeader
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            query={query}
          />
          {!isBlocked && (
            <div class={s.header}>
              <h1>{query}</h1>
              <ShareWidget
                palette={currentPalette}
                active={currentPalette.length > 0}
              />
            </div>
          )}
          {!isBlocked && (
            <PaletteRow
              palette={currentPalette}
              onRemove={(i) => this.props.removePaletteColor(i)}
              visible={currentPalette.length > 0}
            />
          )}
          <ResultsGrid
            pinned={currentPalette.map(c => c.color)}
            query={query}
            items={items}
            onPin={onPin}
            adChild={adChild}
          />
          {isBlocked && (
            <div class={s.header}>
              <h1>Page not found.</h1>
            </div>
          )}
        </div>
      )
    }
  }
)
