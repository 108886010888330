export function hexToRgb (hex) {
  const bigint = parseInt(hex.substr(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return { r, g, b }
}

export function contrast (hex) {
  const rgb = hexToRgb(hex)
  const o = Math.round(((parseInt(rgb.r) * 299) + (parseInt(rgb.g) * 587) + (parseInt(rgb.b) * 114)) / 1000)

  return (o <= 180) ? 'dark' : 'light'
}
