import { Component } from 'preact'
import { route } from 'preact-router'
import { getMobileOperatingSystem } from '../utils/mobile'
import Logo from '../components/logo'
import SearchInput from '../components/search-input'
import Title from '../components/title'
import OgImage from '../components/ogimage'
import Favicon from '../components/favicon'
import GridLoop from '../components/grid-loop'
import examples from '../hoc/examples'
import ad from '../ad.json'
import s from './home.sass'

const onSubmit = (ev) => {
  ev.preventDefault()
  const query = ev.currentTarget.elements[0].value
  route('/' + encodeURIComponent(query))
}

export default examples(
  class extends Component {
    componentDidMount () {
      if (!getMobileOperatingSystem() && this.input.base) {
        this.input.base.focus()
      }

      if (ad && ad.homeTopMessage) {
        const lastSeenMessage = document.cookie.match(/(?:^|; )picular-last-seen-message=([^;]+)/) || []
        this.setState({ showMessage: lastSeenMessage[1] != ad.id }) // eslint-disable-line
      }
    }

    onCloseMessage = () => {
      const expires = new Date()
      expires.setDate(expires.getDate() + 30)

      document.cookie = `picular-last-seen-message=${ad.id}; expires=${expires.toString()}; path=/`
      this.setState({ showMessage: false })
    }

    render ({ example }, { showMessage }) {
      return (
        <div class={s.view}>
          {ad && ad.homeTopMessage && showMessage && (
            <div class={s.ad}>
              {!!ad.homeTopMessage.icon && (
                <div class={s.icon}>
                  <img src={ad.homeTopMessage.icon} />
                </div>
              )}
              <p class={s.text} dangerouslySetInnerHTML={{ __html: ad.homeTopMessage.text }} />
              <button class={s.close} onClick={() => this.onCloseMessage()}>
                <img src={require('../images/close-round.svg').default} alt='link icon' width='24' height='24' />
              </button>
            </div>
          )}

          <div class={s.inner}>
            <Title title={null} />
            <OgImage image={null} />
            <Favicon />

            <div class={s.header}>
              <Logo
                primary='#17a8d6'
                secondary='#8bd3eb'
              />

              <div class={s.headerLinks}>
                <a href='https://www.instagram.com/picular.co/' target='_blank' rel='noopener noreferrer' title='Follow us on Instagram'>
                  <img src={require('../images/icon-instagram.svg').default} alt='Future Memories logo' width='20' height='20' />
                  <span>Follow us</span>
                </a>

                <a href='https://futurememories.se/' target='_blank' rel='noopener noreferrer' title='Crafted by Future Memories'>
                  <span>Crafted by</span>
                  <img src={require('../images/futurememories.svg').default} alt='Future Memories logo' width='20' height='20' />
                </a>
              </div>
            </div>

            <form class={s.input} onSubmit={onSubmit} action='#'>
              <h2>The color of anything.</h2>
              <label for='search'>Search text</label>
              <SearchInput placeholder={example.placeholder} ref={e => { this.input = e }} />
              <button type='submit'>Search</button>
            </form>
            <div class={s.results}>
              <GridLoop colors={example.colors} />
            </div>
          </div>
        </div>
      )
    }
  }
)
