/* global sessionStorage */

import createStore from 'unistore'

export default createStore({
  currentPalette: (
    typeof sessionStorage !== 'undefined'
      ? JSON.parse(sessionStorage.getItem('currentPalette') || null) || []
      : []
  )
})
