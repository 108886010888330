import { Component } from 'preact'
import { shuffle } from '../utils/array'
import ad from '../ad.json'
import gen from 'random-seed'
import Clipboard from 'clipboard'
import Icon from './icon'
import cx from 'classnames'
import s from './results-grid.sass'

const AMOUNT_COLORS = ad && ad.search ? 21 : 20

const heightToSpan = (height) => {
  switch (height) {
    case 1: return 'span 5'
    case 2: return 'span 7'
    default: return 'span 9'
  }
}

const Ad = (data) => {
  const { ad } = data.item
  return (
    <a
      // Call it other instead of ad to potentially avoid ad blocker
      class={cx(s.other, data.item.show && s.show)}
      style={{ gridRowEnd: heightToSpan(data.item.height) }}
      href={ad.href}
      target='_BLANK'
      rel='noopener noreferrer'
    >
      <div class={s.image}>
        <img src={ad.image} style={{ objectPosition: ad.objectPosition }} />
      </div>
      <p class={s.title}>{ad.title}</p>
      <div class={s.row}>
        <p>{ad.text}</p>
        <img src={require('../images/link.svg').default} alt='link icon' width='28' height='28' />
      </div>
    </a>
  )
}

class Item extends Component {
  componentWillUnmount () {
    if (this.clipboard) {
      this.clipboard.destroy()
      this.clipboard = null
    }
  }

  setupClipboard = (el) => {
    if (!el) return

    if (this.clipboard) {
      this.clipboard.destroy()
      this.clipboard = null
    }

    this.clipboard = new Clipboard(el, {
      text: () => this.props.item.color
    })

    this.clipboard.on('success', (e) => {
      this.showMessage('HEX copied!')
    })
  }

  onPin = () => {
    this.props.onPin({
      color: this.props.item.color,
      img: this.props.item.img,
      query: this.props.query
    })

    this.showMessage(this.props.isPinned ? 'Unpinned.' : 'Pinned!')
  }

  showMessage = (message) => {
    clearTimeout(this.messageTimeout)

    this.setState({ message, showMessage: true })
    this.messageTimeout = setTimeout(_ => {
      this.setState({ showMessage: false })
    }, 700)
  }

  render ({ loading, item, index, isPinned }, { message, showMessage }) {
    return (
      <li
        class={cx(loading && s.loading)}
        style={{ gridRowEnd: heightToSpan(item.height) }}
      >
        {
          loading && (
            <div
              class={s.loader}
              style={{ animationDelay: `${index * 0.03}s` }}
            />
          )
        }
        <div
          class={cx(
            s.inner,
            item.light ? s.light : s.dark,
            !item.img && s.noimage,
            item.show && s.show
          )}
          style={{ backgroundColor: item.color }}
        >
          <button
            type='button'
            tabindex='-1'
            ref={this.setupClipboard}
            class={s.hex}
          >
            {item.color}
          </button>
          <button
            type='button'
            onClick={() => this.onPin()}
            class={cx(isPinned && s.active)}
          >
            <Icon icon='pin' />
          </button>
          <span class={s.image}>
            <div class={s.icon} tabindex='0'>
              <Icon icon='image' />
            </div>
            {item.img && <figure><img src={item.img} /></figure>}
          </span>
          <div class={cx(s.message, showMessage && s.show)}>{message}</div>
        </div>
      </li>
    )
  }
}

export default class extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount () {
    this.newQuery(this.props.query)
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps (props) {
    if (props.query !== this.props.query) {
      this.newQuery(props.query)
    }

    if (props.items !== this.props.items) {
      // Show timer
      shuffle(
        Array.from({ length: props.items.length }, (_, i) => i * 0.03)
      ).forEach((seconds, i) => {
        setTimeout(() => {
          const items = this.state.items.concat()
          items[i].show = true
          this.setState({ items })
        }, seconds * 1000)
      })

      this.setState({
        loading: false,
        items: props.items.map((item, i) => ({
          ...this.state.items[i],
          ...item
        }))
      })
    }
  }

  newQuery (query) {
    const random = gen(query)

    this.setState({
      loading: true,
      items: Array.from({ length: AMOUNT_COLORS }, (_, i) => ({
        height: query.toLowerCase() === 'chess' ? 1 : random.intBetween(i === this.props.adChild ? 2 : 1, 3),
        show: false
      }))
    })
  }

  render ({ query, onPin, pinned }, { loading, items = [] }) {
    return (
      <ul class={cx(s.grid, query.toLowerCase() === 'chess' && s.chess)}>
        {
          items.map((item, i) => (
            item.ad
              ? (
                <Ad index={i} item={item} />
                )
              : (
                <Item
                  item={item}
                  index={i}
                  key={i}
                  loading={loading}
                  onPin={onPin}
                  isPinned={!!pinned.find(hex => hex === item.color)}
                  query={this.props.query}
                />
                )
          ))
        }
      </ul>
    )
  }
}
