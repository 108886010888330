/* globals Image */
import { Component } from 'preact'
import { hexToRgb } from '../utils/color'
import faviconBase from '../images/favicon-base.png'

const BASE_COLOR = '#778A99'
let faviconLink

export default class extends Component {
  componentDidMount () {
    this.generateFavicon()
  }

  componentDidUpdate () {
    this.generateFavicon()
  }

  deleteDefault () {
    const defaultIcon = document.querySelector('link[rel="shortcut icon"]')
    const isCustom = defaultIcon && defaultIcon.getAttribute('data-custom')

    if (defaultIcon && !isCustom) {
      defaultIcon.remove()
    }
  }

  generateFavicon () {
    const { primary, secondary } = this.props

    if (typeof document === 'undefined') return

    const docHead = document.getElementsByTagName('head')[0]
    const canvas = document.createElement('canvas')
    canvas.width = 192
    canvas.height = 192

    const primaryRgb = hexToRgb(primary || BASE_COLOR)
    const secondaryRgb = hexToRgb(secondary || BASE_COLOR)
    const img = new Image()

    this.deleteDefault()

    img.onload = () => {
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const { data } = imgData

      for (let i = 0; i < data.length; i += 4) {
        const rgb = (i <= 25800 * 4 ? primaryRgb : secondaryRgb)
        data[i + 0] = rgb.r
        data[i + 1] = rgb.g
        data[i + 2] = rgb.b
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.putImageData(imgData, 0, 0)

      if (faviconLink) {
        docHead.removeChild(faviconLink)
        faviconLink = null
      } else {
        // If first time creating favicon, suppress favicon.ico
        const suppresser = document.createElement('link')
        suppresser.rel = 'shortcut icon'
        suppresser.type = 'image/x-icon'
        suppresser.href = 'data:image/x-icon;,'
        suppresser.setAttribute('data-custom', true)
        docHead.appendChild(suppresser)
      }

      faviconLink = document.createElement('link')
      faviconLink.setAttribute('data-custom', true)
      faviconLink.rel = 'shortcut icon'
      faviconLink.type = 'image/x-icon'
      faviconLink.href = canvas.toDataURL()
      docHead.appendChild(faviconLink)

      // document.body.appendChild(canvas)
      // canvas.style.position = 'absolute'
      // canvas.style.top = '0'
      // canvas.style.left = '0'
    }
    img.src = faviconBase
  }
}
