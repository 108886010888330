import s from './search-input.sass'

export default ({ value, placeholder }) => (
  <input
    class={s.input}
    defaultValue={value}
    type='search'
    name='search'
    id='search'
    placeholder={placeholder || 'forest, water, desert and so on...'}
    required
    minlength='2'
    autocomplete='off'
    autocorrect='off'
    autocapitalize='off'
    spellcheck='false'
  />
)
