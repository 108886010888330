import { Component } from 'preact'
import { Provider } from 'unistore/preact'
import Router from 'preact-router'
import Match from 'preact-router/match'
import Home from './routes/home'
import Palette from './routes/palette'
import Search from './routes/search'
import Feedback from './routes/admin/feedback'
import store from './store'
import FeedbackTool from './components/feedback'
import { auth } from './firebase'
import { onAuthStateChanged } from 'firebase/auth'
import './index.sass'

const disableFeedBackToolUrls = [
  '/',
  '/admin/feedback-tool'
]

if (typeof window !== 'undefined') {
  onAuthStateChanged(auth, user => { store.setState({ user }) })

  require('browsernizr/test/touchevents')
  require('browsernizr')
}

require('./store-palette')(store)

export default class extends Component {
  render (pro) {
    return (
      <Provider store={store}>
        <div class='app'>
          <Match>
            {({ path }) => !disableFeedBackToolUrls.includes(path) && <FeedbackTool route={path} />}
          </Match>
          <Router>
            <Home path='/' />
            <Palette path='/p/:code' />
            <Search path='/:query' />
            <Feedback path='/admin/feedback-tool' />
          </Router>
        </div>
      </Provider>
    )
  }
}
