/* globals sessionStorage */

module.exports = (store) => {
  const { currentPalette } = store.getState()

  store.subscribe(state => {
    if (currentPalette !== state.currentPalette) {
      sessionStorage.setItem('currentPalette', JSON.stringify(state.currentPalette))
    }
  })
}
