import { Component } from 'preact'
import { contrast } from '../utils/color'
import { API_URL } from '../settings'
import cx from 'classnames'
import Title from '../components/title'
import OgImage from '../components/ogimage'
import Favicon from '../components/favicon'
import SearchHeader from '../components/search-header'
import s from './palette.sass'

export default class extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount () {
    this.setState({ loading: true })

    fetch(API_URL + '/get-palette?key=' + this.props.code)
      .then(r => (
        !r.ok
          ? false
          : r.json()
      ))
      .then(data => {
        if (!data) {
          return this.setState({ loading: false })
        }

        this.setState({ colors: data.colors, loading: false })
      })
  }

  render (_, { loading, colors }) {
    return (
      <div class={s.view}>
        <Title title={null} />
        <OgImage image={`${API_URL}/share-image?ogimage=true&id=${this.props.code}`} />
        <Favicon />
        <SearchHeader />
        {
          !loading && (
            colors
              ? (
                <div class={s.grid}>
                  {
                    colors.map((c, i) => (
                      <div
                        key={'color_' + i}
                        class={cx(s.item, contrast(c.color) === 'dark' && s.dark)}
                        style={{ backgroundColor: c.color }}
                      >
                        {c.color}
                      </div>
                    ))
                  }
                </div>
                )
              : <p class={s.message}>Palette not found</p>
          )
        }
      </div>
    )
  }
}
