import { Component } from 'preact'
import { shuffle } from '../utils/array'

export default (Wrapped) => {
  let queue = []
  let timeout
  let examples

  return class extends Component {
    componentDidMount () {
      if (!examples) {
        import('./examples.json')
          .then(data => { examples = data.default })
          .then(_ => this.newSearchIn(1000))
      } else {
        this.newSearchIn(1000)
      }
    }

    componentWillUnmount () {
      clearTimeout(timeout)
    }

    doSearch = (data) => {
      const write = (text, length) => {
        if (length <= text.length) {
          this.setState({ placeholder: data.text.substr(0, length) + '|' })
          timeout = setTimeout(() => write(text, length + 1), 200)
        } else {
          this.setState({ placeholder: data.text })

          timeout = setTimeout(() => {
            this.setState({ colors: data.colors })
            this.newSearchIn(5000)
          }, 200)
        }
      }

      write(data.text, 0)
    }

    newSearchIn = (timer) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        if (queue.length === 0) {
          queue = shuffle(examples.slice())
        }

        this.doSearch(queue.pop())
      }, timer)
    }

    render (_, { placeholder, colors }) {
      return <Wrapped example={{ placeholder, colors }} />
    }
  }
}
