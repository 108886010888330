import { contrast } from '../utils/color'
import cx from 'classnames'
import Icon from '../components/icon'
import s from './palette-row.sass'

const getExtraCols = (length) => (
  length !== 0 && length % 4 === 0
    ? 0
    : 4 - length % 4
)

export default ({ palette, onRemove, visible }) => {
  const extraCols = getExtraCols(palette.length)

  return (
    <div class={cx(s.box, visible && s.show)}>
      {
        palette.map((hex, i) => (
          <div
            class={cx(
              s.item,
              contrast(hex.color) === 'dark' && s.dark
            )}
            style={{ backgroundColor: hex.color }}
            key={'palette-' + i}
          >
            <span class={s.color}>{hex.color}</span>
            <button type='button' class={s.remove} onClick={_ => onRemove(i)}>
              <Icon icon='close' />
            </button>
          </div>
        ))
      }
      {
        Array.from({ length: extraCols }, (_, i) => (
          <div
            key={'palette-' + (i + palette.length)}
            class={cx(
              s.item,
              s.empty,
              s['empty' + (i + (4 - extraCols))]
            )}
          />
        ))
      }
    </div>
  )
}
