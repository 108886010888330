export default ({ icon, color, ...props }) => {
  switch (icon) {
    case 'close':
      return (
        <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
          <path fill='currentColor' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z' />
        </svg>
      )

    case 'pin':
      return (
        <svg aria-hidden='true' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
          <path d='M12.966 12.08c.667.775.861 1.86.491 2.825l-.098.256a1 1 0 0 1-1.641.349l-.879-.879-1.88-1.88-7.696 7.043a.66.66 0 0 1-.912-.02.668.668 0 0 1-.026-.918l6.953-7.786-1.91-1.91-.878-.878a1 1 0 0 1 .35-1.64l.256-.099a2.797 2.797 0 0 1 2.824.491l4.323-3.602a2.522 2.522 0 0 1-.135-2.381l.142-.304a1 1 0 0 1 1.613-.285l5.676 5.675a1 1 0 0 1-.286 1.613l-.304.142c-.777.362-1.671.3-2.38-.135l-3.603 4.323z' fill='currentColor' />
        </svg>
      )

    case 'image':
      return (
        <svg aria-hidden='true' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
          <g transform='translate(1 1)' stroke='currentColor' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'>
            <rect width='18' height='18' rx='2' />
            <circle cx='5.5' cy='5.5' r='1.5' />
            <path d='M18 12l-5-5L2 18' />
          </g>
        </svg>
      )

    case 'link':
      return (
        <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
          <path d='M9.932 6.07a4.152 4.152 0 0 1 0 5.877l-1.838 1.837a4.16 4.16 0 0 1-5.879 0 4.159 4.159 0 0 1 0-5.877L3.23 6.893a.436.436 0 0 1 .746.29c.019.484.105.97.266 1.44a.44.44 0 0 1-.105.455l-.357.358a1.985 1.985 0 0 0-.032 2.788 1.972 1.972 0 0 0 2.799.014l1.837-1.837A1.967 1.967 0 0 0 8.1 7.382a.438.438 0 0 1-.19-.345 1.09 1.09 0 0 1 .32-.814l.575-.576a.439.439 0 0 1 .563-.048c.202.14.39.298.562.47h.001l.001.001zm3.853-3.854a4.16 4.16 0 0 0-5.879 0L6.07 4.053a4.153 4.153 0 0 0 .56 6.348.439.439 0 0 0 .565-.047l.574-.575a1.09 1.09 0 0 0 .322-.816.438.438 0 0 0-.19-.345 1.966 1.966 0 0 1-.284-3.019l1.837-1.837a1.972 1.972 0 0 1 2.799.014c.754.78.74 2.025-.032 2.788l-.357.358a.44.44 0 0 0-.104.454c.16.47.246.957.265 1.442a.437.437 0 0 0 .746.29l1.015-1.015a4.159 4.159 0 0 0 0-5.877h-.001z' fill={color || '#788A99'} />
        </svg>
      )

    case 'facebook':
      return (
        <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
          <path d='M6.276 15.014h2.55V8.011h1.912l.379-2.189H8.825V4.236c0-.51.331-1.048.803-1.048h1.303V1H9.335v.01c-2.497.09-3.01 1.537-3.055 3.056h-.004v1.756H5v2.189h1.276v7.003z' fill='#3B5998' />
        </svg>
      )

    case 'twitter':
      return (
        <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
          <path d='M14.713 3.245c-.395.253-1.303.621-1.75.621A2.806 2.806 0 0 0 8.2 6.432C6.098 6.376 3.8 5.323 2.416 3.52c-.85 1.473-.115 3.11.85 3.707-.33.025-.938-.038-1.224-.317-.02.977.45 2.272 2.164 2.742-.33.177-.914.126-1.168.089.089.825 1.244 1.903 2.508 1.903-.45.521-2.145 1.466-4.046 1.165a8.43 8.43 0 0 0 4.389 1.241c4.527 0 8.042-3.669 7.853-8.194l-.002-.016.002-.035-.002-.04a4.84 4.84 0 0 0 1.36-1.437c-.23.126-.916.378-1.556.441.41-.221 1.019-.947 1.169-1.524' fill='#55ACEE' />
        </svg>
      )

    case 'pinterest':
      return (
        <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
          <path d='M0 8a8.005 8.005 0 0 0 4.833 7.35c-.02-.559-.001-1.224.14-1.827.155-.65 1.034-4.375 1.034-4.375s-.256-.513-.256-1.27c0-1.19.69-2.079 1.548-2.079.73 0 1.083.55 1.083 1.207 0 .734-.468 1.831-.71 2.848-.2.852.43 1.547 1.269 1.547 1.52 0 2.544-1.954 2.544-4.27 0-1.758-1.184-3.076-3.34-3.076-2.435 0-3.954 1.816-3.954 3.845 0 .7.207 1.194.531 1.575.149.175.169.246.114.448-.039.147-.125.503-.162.645-.053.203-.219.274-.403.2-1.12-.457-1.644-1.686-1.644-3.067 0-2.281 1.924-5.016 5.738-5.016 3.067 0 5.084 2.219 5.084 4.599 0 3.151-1.752 5.503-4.332 5.503-.867 0-1.683-.469-1.961-1 0 0-.468 1.848-.566 2.207-.168.61-.493 1.219-.794 1.699A8 8 0 1 0 0 8z' fill='#CC2127' />
        </svg>
      )

    case 'google':
      return (
        <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'>
          <g fill='none' fill-rule='evenodd'>
            <path d='M16.469 7.622v1.317c0 2.646-1.037 4.707-2.712 6.003-1.3 1.006-3.248-2.029-2.65-2.315a3.4 3.4 0 0 0 1.76-1.93H8.76V7.621' fill='#2885FB' />
            <path d='M13.84 14.877c-1.311 1.048-3.027 1.617-4.948 1.617-3.373 0-6.083-1.735-7.41-4.284A7.952 7.952 0 0 1 .59 8.522c0-4.458 3.536-7.938 8.06-8.016 2.848-.049 4.05 1.002 4.922 1.926l-2.4 2.327c-.65-.501-1.192-.827-2.544-.827-2.592 0-4.524 1.91-4.524 4.612 0 .464.058.905.17 1.318.52 1.913 2.205 3.206 4.661 3.206.923 0 1.702-.184 2.33-.521' fill='#00AB46' />
            <path d='M1.483 12.214a7.951 7.951 0 0 1-.892-3.692c0-1.275.29-2.47.808-3.528 1.295-2.642 3.531.142 2.941 1.989a5.12 5.12 0 0 0-.235 1.56c0 .46.057.898.167 1.308' fill='#FFBA00' />
            <path d='M1.392 5.008C2.684 2.358 5.415.562 8.65.506c2.848-.049 4.05 1.002 4.922 1.926l-2.4 2.327c-.65-.501-1.192-.827-2.544-.827-2.063 0-3.708 1.21-4.294 3.067' fill='#FD2A23' />
          </g>
        </svg>
      )

    case 'devices':
      return (
        <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
          <defs>
            <path d='M8.546 10.286c.024.102.06.197.108.285.194.36.71.836 1.547 1.429H4.22c1.113-.542 1.821-1.018 2.127-1.429.069-.091.121-.187.16-.285H1.153A1.148 1.148 0 0 1 0 9.143v-8C0 .512.517 0 1.154 0h12.692C14.483 0 15 .512 15 1.143v8c0 .631-.517 1.143-1.154 1.143h-5.3z' id='a' />
          </defs>
          <g transform='translate(.2 2)' fill='none' fill-rule='evenodd'>
            <path d='M6.293 11.429H8.6a2.729 2.729 0 0 1-.454-.587 1.728 1.728 0 0 1-.162-.425l-.167-.703h6.03a.574.574 0 0 0 .576-.571v-8a.574.574 0 0 0-.577-.572H1.154a.574.574 0 0 0-.577.572v8c0 .315.258.571.577.571H7.34l-.296.775c-.057.148-.136.289-.234.421-.127.171-.3.343-.518.519z' stroke='#778A99' stroke-width='1.182' />
            <mask id='b' fill='#fff'>
              <use xlinkHref='#a' />
            </mask>
            <path d='M.917 9.143c3.614-1.69 6.21-3.016 7.789-3.98 1.578-.964 3.326-2.291 5.244-3.98v7.96H.917z' fill-opacity='.179' fill='#778A99' fill-rule='nonzero' mask='url(#b)' />
            <rect stroke='#778A99' stroke-width='1.182' fill='#F0F0F0' fill-rule='nonzero' x='11.538' y='4.571' width='3.462' height='6.857' rx='1.182' />
          </g>
        </svg>
      )
  }
}
