import { Component } from 'preact'
import { connect } from 'unistore/preact'
import { API_URL } from '../../settings'
import Icon from '../../components/icon'
import cx from 'classnames'
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { auth } from '../../firebase'
import s from './feedback.sass'

/* export default connect('user')(({ user }) => {
  useEffect(() => { if (user) this.getff() }, [user])
  return <div />
}) */

const randomHEX = () => {
  let hex = Math.floor(Math.random() * 16777215).toString(16)
  if (hex.length < 6) {
    hex = `${hex}${hex}`.slice(0, 6)
  }
  return '#' + hex
}

export default connect('user')(
  class extends Component {
    state = { count: 100 }

    onGoogle = () => {
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({ hd: 'futurememories.se' })
      signInWithPopup(auth, provider)
    }

    getFeedback = async (user) => {
      this.setState({ loading: true })
      const token = await user.getIdToken()
      const feedbackData = await fetch(API_URL + '/feedback', { headers: { Authorization: 'Bearer ' + token } }).then(r => r.json())
      this.setState({ feedbackData, loading: false })
    }

    componentDidMount () {
      let loadingColors = ''
      for (let i = 1; i < 4; i++) { loadingColors += `--color-block-${i}: ${randomHEX()}; ` }
      this.setState({ loadingColors })

      if (this.props.user) this.getFeedback(this.props.user)
    }

    componentDidUpdate (prevProps) {
      if (prevProps.user !== this.props.user && this.props.user) {
        this.getFeedback(this.props.user)
      }
    }

    // removeFeedback = (id) => {
    //   const { feedbackData } = this.state
    //   const currentIndex = feedbackData.findIndex(o => o.id === id)
    //   if (currentIndex >= 0) {
    //     feedbackData.splice(currentIndex, 1);
    //   }
    //   this.setState({ feedbackData })
    // }

    render (_, { feedbackData = [], count, loading, loadingColors }) {
      const { id: feedbackId } = this.props.matches
    const selectedFeedback = feedbackId && feedbackData.filter(feed => feed.id == feedbackId) // eslint-disable-line

      return (
        <div class={s.view}>
          <div class={cx(s.loading, loading && s.show)} style={loadingColors}>
            <div class={s.colorCard} />
          </div>
          {feedbackData.length === 0
            ? (
              <div class={s.login}>
                <div class={s.inner}>
                  <h1>Feedback Tool</h1>
                  <div class={s.actions}>
                    <p class={s.loginWith}>Log in with</p>
                    <div class={s.loginOptions}>
                      <button onClick={() => this.onGoogle()} class={s.google}>
                        <Icon icon='google' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              )
            : (
              <div class={s.feedbackBlock}>
                <div class={s.inner}>
                  <a href='/admin/feedback-tool' class={s.title}>Feedback Tool</a>
                  <div class={s.feedbacks}>
                    {(selectedFeedback || feedbackData.slice(0, count)).map((feedback, i) => {
                      const date = new Date(feedback.date)
                      const currentDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
                      return (
                        <article key={`row_${i}`}>
                          <div class={s.header}>
                            <a href={`/admin/feedback-tool?id=${feedback.id}`}><Icon icon='link' color='#768A98' /></a>
                            <span title={feedback.userAgent}><Icon icon='devices' /></span>
                            <time datetime={feedback.date}>{currentDate}</time>
                          </div>
                          <p>{feedback.feedback}</p>
                        </article>
                      )
                    })}
                  </div>
                  {!selectedFeedback && feedbackData.length > count && (
                    <button onClick={() => this.setState({ count: count + 100 })}>Read more</button>
                  )}
                </div>
              </div>
              )}
        </div>
      )
    }
  })
