import { route } from 'preact-router'
import Logo from './logo'
import SearchInput from './search-input'
import LoveLink from './love-link'
import s from './search-header.sass'

const onSubmit = (ev) => {
  ev.preventDefault()
  const query = ev.currentTarget.elements[0].value
  route('/' + encodeURIComponent(query))
  ev.currentTarget.elements[0].blur()
}

export default ({ query, primaryColor, secondaryColor }) => (
  <form class={s.header} onSubmit={onSubmit} action='#'>
    <a href='/' class={s.logo}>
      <Logo primary={primaryColor} secondary={secondaryColor} />
    </a>
    <div class={s.input}>
      <label for='search'>Search text</label>
      <SearchInput value={query} />
      <button type='submit'>Search</button>
    </div>
    <LoveLink class={s.love} />
  </form>
)
