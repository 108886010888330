import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

let app
let auth

if (typeof window !== 'undefined') {
  app = initializeApp(require('./firebase.config'))
  auth = getAuth(app)
}

export { app, auth }
