import { Component } from 'preact'
import { API_URL } from '../settings'
import s from './feedback.sass'

export default class extends Component {
  state = { loading: false }

  handleTextChange = (e) => {
    const { value } = e.currentTarget
    this.setState({ value })
  }

  handleEmoji = (emoji) => {
    document.getElementById('feedback-text').focus()
    this.setState({ value: `${this.state.value ? this.state.value + emoji : emoji}` })
  }

  removeFocus = () => {
    document.activeElement.blur()
  }

  takeFeedback = async (ev) => {
    ev.preventDefault()
    this.setState({ loading: true })

    if (this.state.value < 10) {
      return this.setState({
        feedbackSent: false,
        feedbackError: 'Needs to contains at least 10 characters.',
        loading: false
      })
    }

    const data = await fetch(API_URL + '/feedback', {
      method: 'PUT',
      body: JSON.stringify({
        feedback: this.state.value,
        userAgent: navigator.userAgent
      })
    }).then(r => r.json())

    if (data && data.added) {
      this.setState({ feedbackSent: true, feedbackError: false, loading: false, value: '' })
      this.removeFocus()
      setTimeout(() => {
        this.setState({ feedbackSent: false })
      }, 3000)
    } else {
      this.setState({ feedbackSent: false, feedbackError: 'Oops, something went wrong.', loading: false })
    }
  }

  render ({ route }, { value, loading, feedbackSent }) {
    // Make sure emojis are length is 1
    const currentLength = value && value.replace(/❤️/g, 'l').length

    return (
      <form
        id='feedback'
        tabindex='0'
        class={s.feedback}
        onSubmit={(ev) => this.takeFeedback(ev)}
      >
        <div class={s.opend}>
          <textarea
            id='feedback-text'
            ref={e => { this.input = e }}
            onKeyUp={this.handleTextChange}
            placeholder='What is your one wish Picular could do? ✏️'
            value={value}
          />
          <div class={s.emojis}>
            {['🔥', '😁', '❤️', '🙌', '😒'].map((emoji, i) => (
              <button
                key={'emoji_' + i}
                type='button'
                onClick={() => this.handleEmoji(emoji)}
              >
                {emoji}
              </button>
            ))}
          </div>
          <div class={s.buttons}>
            <button class={(loading || !value || currentLength < 10) && s.fade}>{loading ? 'Sending...' : 'Send'}</button>
            <button type='button' onClick={() => this.removeFocus()}>
              <img src={require('../images/close.svg').default} />
            </button>

          </div>

        </div>
        <div class={s.start}>
          <img alt='Thank you' class={feedbackSent ? s.show : s.hide} src={require('../images/peace-out.png').default} />
          <img alt='Feedback' class={!feedbackSent ? s.show : s.hide} src={require('../images/pen.png').default} />
        </div>
      </form>
    )
  }
}
