import { useState, useEffect } from 'preact/hooks'
import cx from 'classnames'
import gen from 'random-seed'
import s from './grid-loop.sass'

const MAX_HEIGHT_DIFF = 1
const RANDOM_SEED = 'kludd'

const generateGridOffsets = (colors, oldOffsets) => {
  // const random = gen(colors ? '' + Math.random() : 'DEFAULT')
  const random = gen(RANDOM_SEED)

  return (
    Array.from({ length: 9 }, (_, i) => {
      const oldOffset = (oldOffsets && oldOffsets[i] != null ? oldOffsets[i] : undefined)
      const newOffset = (random.random() * 8)
      let offset = newOffset

      if (oldOffset != null) {
        const offsetDiff = newOffset - oldOffset
        const cappedOffsetDiff = Math.max(-MAX_HEIGHT_DIFF, Math.min(MAX_HEIGHT_DIFF, offsetDiff))
        offset = oldOffset + cappedOffsetDiff
      }

      return offset
    })
  )
}

const generateGridSizes = (colors, oldSizes) => {
  let colorIndex = 0
  // const random = gen(colors ? '' + Math.random() : 'DEFAULT')
  const random = gen(RANDOM_SEED)

  return (
    Array.from({ length: 9 }, (_, i) => (
      Array.from({ length: 3 }, (_, j) => {
        const oldHeight = (oldSizes && oldSizes[i] && oldSizes[i][j] && oldSizes[i][j].height) || undefined
        const newHeight = (20.5 + random.random() * 10)
        let height = newHeight

        if (oldHeight) {
          const heightDiff = newHeight - oldHeight
          const cappedHeightDiff = Math.max(-MAX_HEIGHT_DIFF, Math.min(MAX_HEIGHT_DIFF, heightDiff))
          height = oldHeight + cappedHeightDiff
        }

        return {
          key: `item_${i}_${j}`,
          height,
          color: colors && colors[colorIndex] ? colors[colorIndex++].color : undefined
        }
      })
    ))
  )
}

const DEFAULT_OFFSETS = generateGridOffsets()
const DEFAULT_SIZES = generateGridSizes()

function GridItem ({ item }) {
  return (
    <div
      style={{
        height: item.height + 'rem',
        backgroundColor: item.color
      }}
    />
  )
}

export default function GridLoop ({ colors }) {
  const [offsets, setOffsets] = useState(DEFAULT_OFFSETS)
  const [sizes, setSizes] = useState(DEFAULT_SIZES)

  useEffect(() => {
    if (!colors) {
      setOffsets(DEFAULT_OFFSETS)
      setSizes(DEFAULT_SIZES)
      return
    }

    setOffsets(oldOffsets => generateGridOffsets(colors, oldOffsets))
    setSizes(oldSizes => generateGridSizes(colors, oldSizes))
  }, [colors])

  return (
    <div class={s.loop}>
      <div class={s.grid}>
        {
          sizes.map((column, i) => (
            <div
              key={i}
              class={cx(s.column, !colors && s.loading)}
              style={{ transform: `translateY(${offsets[i]}rem)` }}
            >
              {
                column.map((item) => (
                  <div class={s.inner} key={item.key}>
                    <GridItem item={item} />
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}
