import { Component } from 'preact'

const updateTitle = (suffix) => {
  const title = (
    suffix
      ? 'Picular - ' + suffix
      : 'Picular'
  )

  if (typeof document !== 'undefined') {
    document.title = title
    document.querySelector('title').innerText = title
  }
}

export default class extends Component {
  componentDidMount () {
    updateTitle(this.props.title)
  }

  componentDidUpdate (props) {
    if (props.title !== this.props.title) {
      updateTitle(this.props.title)
    }
  }
}
