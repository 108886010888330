import { Component } from 'preact'

export default class extends Component {
  componentDidMount () {
    const ogimage = this.props.image ? this.props.image : 'https://picular.co/ogimage.jpg'

    if (typeof document !== 'undefined') {
      document.querySelector('meta[property="og:image"]').content = ogimage
      document.querySelector('meta[name="twitter:image"]').content = ogimage
    }
  }
}
