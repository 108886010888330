import { Component } from 'preact'
import { API_URL } from '../settings'
import cx from 'classnames'
import Clipboard from 'clipboard'
import Icon from './icon'
import popup from '../utils/popup'
import s from './share-widget.sass'

export default class extends Component {
  componentWillUnmount () {
    if (this.clipboard) {
      this.clipboard.destroy()
      this.clipboard = null
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.url !== this.state.url) {
      if (this.clipboard) {
        this.clipboard.destroy()
        this.clipboard = null
      }

      if (this.state.url && this.link) {
        this.clipboard = new Clipboard(this.link, {
          text: () => this.state.url
        })

        this.clipboard.on('success', (e) => {
          this.setState({ linkCopied: true })
          setTimeout(() => {
            this.setState({ linkCopied: false })
          }, 3600)
          e.clearSelection()
        })
      }
    }

    if (JSON.stringify(prevProps.palette) !== JSON.stringify(this.props.palette)) {
      this.setState({
        url: null,
        key: null
      })
    }
  }

  getShareUrl = () => {
    this.setState({ loading: true })

    fetch(API_URL + '/save-palette', {
      method: 'POST',
      body: JSON.stringify({
        colors: this.props.palette
      })
    })
      .then(r => (
        !r.ok
          ? false
          : r.text()
      ))
      .then(key => {
        if (!key) {
          return this.setState({ loading: false })
        }

        this.setState({
          loading: false,
          url: window.location.origin + '/p/' + key,
          key
        })
      })
  }

  render ({ active }, { url, key, loading }) {
    return (
      <section class={s.widget}>

        <div class={cx(s.block, !active && s.show)}>
          <h4>Pin your favorites! <Icon icon='pin' /></h4>
        </div>

        <div class={cx(s.block, active && s.show)}>
          <h4>Share your palette!</h4>
          {
            url
              ? (
                <div class={s.buttons}>
                  <button
                    type='button'
                    onClick={() => popup('https://twitter.com/intent/tweet?text=Check%20out%20this%20awesome%20palette%21%0A%0A' + encodeURI(url), 557, 275) + '&source=webclient'}
                  >
                    <Icon icon='twitter' />
                  </button>

                  <button
                    type='button'
                    onClick={() => popup('https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(url), 557, 647)}
                  >
                    <Icon icon='facebook' />
                  </button>

                  <button
                    type='button'
                    onClick={() => popup(`https://pinterest.com/pin/create/button/?url=${encodeURI(url)}&media=https://backend.picular.co/api/share-image?id=${key}&description=Picular%20Palette`, 750, 750)}
                  >
                    <Icon icon='pinterest' />
                  </button>

                  <div class={s.link}>
                    {this.state.linkCopied && (
                      <div class={s.copied}>
                        Copied!
                      </div>
                    )}
                    <button
                      type='button'
                      class={this.state.linkCopied && s.inactive}
                      ref={el => { this.link = el }}
                    >
                      <Icon icon='link' />
                    </button>
                  </div>

                </div>
                )
              : (
                <button
                  type='button'
                  class={cx(s.share, loading && s.loading)}
                  onClick={() => this.getShareUrl()}
                >
                  <span>Share</span>
                </button>
                )
          }
        </div>
      </section>
    )
  }
}
